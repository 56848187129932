/*!
 * Shuffle CSS Extension for Bootstrap
 * Copyright 2023 The Shuffle Team
 */

@import "_variables";

body {
    @if $enable-antialiasing {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.alert {
    color: $alert-color;
    font-size: $alert-font-size;

    border-top-width: $alert-border-top-width;
    border-right-width: $alert-border-right-width;
    border-bottom-width: $alert-border-bottom-width;
    border-left-width: $alert-border-left-width;

    @if $enable-rounded {
        border-top-right-radius: $alert-border-top-right-radius;
        border-top-left-radius: $alert-border-top-left-radius;
        border-bottom-right-radius: $alert-border-bottom-right-radius;
        border-bottom-left-radius: $alert-border-bottom-left-radius;
    }

    @else {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.badge {
    text-transform: $badge-text-transform;
}

.btn {
    text-transform: $btn-text-transform;
}

.navbar-toggler {
    border: $navbar-toggler-border solid transparent !important;
}

.medium {
    font-size: $font-size-sm;
}

.row .container,
.container .container {
    max-width: none;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.transform-rotate-45deg {
    transform: rotate(45deg);
}

.btn span + svg, a span + svg.rotate-45 {
    transition-duration: 250ms;
}

.btn:hover span + svg.rotate-45, a:hover span + svg.rotate-45 {
    transform: rotate(45deg);
}

.spin {
    animation: spin 24s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: $headings-letter-spacing;
}
